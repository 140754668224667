import { gql } from "@apollo/client";

const ResendSignInMFAPasscode_Shared_Mutation = gql`
  mutation ResendSignInMFAPasscode_Shared_Mutation(
    $input: AuthUserTwoFactorAuthenticationResendInput!
  ) {
    auth {
      userResendTwoFactorAuthentication(input: $input) {
        sent
        errors {
          message
        }
      }
    }
  }
`;

export { ResendSignInMFAPasscode_Shared_Mutation };
