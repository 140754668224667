import type * as Types from '@shared/types/graphqlGenerated.ts';

import * as Operations from './UserRolesType.query';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserRolesTypeSharedQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserRolesTypeSharedQuery = { viewer?: Types.Maybe<{ user: (
      Pick<Types.User, 'id'>
      & { roles: Array<Pick<Types.Admin, 'id' | 'type'> | Pick<Types.Clerk, 'id' | 'type'> | Pick<Types.OrganizationManager, 'id' | 'type'> | Pick<Types.Patient, 'id' | 'type'> | Pick<Types.Practitioner, 'id' | 'type'>> }
    ) }> };



/**
 * __useUserRolesTypeSharedQuery__
 *
 * To run a query within a React component, call `useUserRolesTypeSharedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRolesTypeSharedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRolesTypeSharedQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRolesTypeSharedQuery(baseOptions?: Apollo.QueryHookOptions<UserRolesTypeSharedQuery, UserRolesTypeSharedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRolesTypeSharedQuery, UserRolesTypeSharedQueryVariables>(Operations.UserRolesType_Shared_Query, options);
      }
export function useUserRolesTypeSharedQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRolesTypeSharedQuery, UserRolesTypeSharedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRolesTypeSharedQuery, UserRolesTypeSharedQueryVariables>(Operations.UserRolesType_Shared_Query, options);
        }
export function useUserRolesTypeSharedQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserRolesTypeSharedQuery, UserRolesTypeSharedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserRolesTypeSharedQuery, UserRolesTypeSharedQueryVariables>(Operations.UserRolesType_Shared_Query, options);
        }
export type UserRolesTypeSharedQueryHookResult = ReturnType<typeof useUserRolesTypeSharedQuery>;
export type UserRolesTypeSharedQueryLazyQueryHookResult = ReturnType<typeof useUserRolesTypeSharedQueryLazyQuery>;
export type UserRolesTypeSharedQuerySuspenseQueryHookResult = ReturnType<typeof useUserRolesTypeSharedQuerySuspenseQuery>;