// @ts-strict-ignore
import type { SerializedStyles } from "@emotion/serialize";
import { useTranslation } from "react-i18next";

import { Typography } from "@aviary";
import { l } from "@shared/locales/i18n";
import type { PatientAdditionalAttributesType } from "@shared/types/PatientAdditionalAttributesType";
import type { PractitionerAdditionalAttributesType } from "@shared/types/PractitionerAdditionalAttributesType";

import { getActionURL } from "../utils/getActionURL";

import AppleSVGComponent from "./assets/Apple";

import * as styles from "./AppleSSOButton.styles";

interface Props {
  formData?: {
    firstName?: string;
    lastName?: string;
    storeName?: string;
    tosAcceptedAt?: string;
  };
  treatmentPlanSlug?: string;
  labRecommendationId?: string;
  storeSlug?: string;
  pracTypeId?: string;
  signUpPage?: string;
  pageVersion?: string;
  disabled?: boolean;
  isOrTextRendered?: boolean;
  additionalAttributes?: PractitionerAdditionalAttributesType & PatientAdditionalAttributesType;
  uuid?: string;
  buttonStyles?: SerializedStyles;
  orStyles?: SerializedStyles;
  isIntake?: boolean;
  requestedPractitionerId?: string;
}

const AppleSSOButton = ({
  formData,
  signUpPage,
  treatmentPlanSlug,
  labRecommendationId,
  storeSlug,
  pracTypeId,
  pageVersion,
  disabled,
  additionalAttributes,
  uuid,
  isOrTextRendered = false,
  buttonStyles,
  orStyles,
  isIntake,
  requestedPractitionerId,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute("content");
  const buttonText = t(l.appleSSO.ContinueWithApple);

  const formStyles = [styles.form, disabled && styles.formDisabled, buttonStyles];
  const submitStyles = [styles.submit, disabled && styles.submitDisabled];
  const logoStyles = [styles.logo, disabled && styles.disabled];
  const appleSSOButtonStyles = [styles.AppleSSOButton, disabled && styles.disabled];

  return (
    <div css={styles.buttonWrapper}>
      <div css={formStyles} {...rest}>
        <form
          action={getActionURL(
            "Apple",
            formData,
            additionalAttributes,
            signUpPage,
            treatmentPlanSlug,
            labRecommendationId,
            storeSlug,
            pracTypeId,
            pageVersion,
            uuid,
            isIntake,
            requestedPractitionerId
          )}
          method="post"
          data-e2e="apple-sso-button"
        >
          <label aria-label={buttonText} css={appleSSOButtonStyles} htmlFor={buttonText}>
            <AppleSVGComponent css={logoStyles} />
            <input
              id={buttonText}
              value={buttonText}
              type="submit"
              css={submitStyles}
              disabled={disabled}
            />
          </label>
          <input type="hidden" name="authenticity_token" value={csrf}></input>
        </form>
      </div>
      {isOrTextRendered && (
        <Typography type="footnote" isMarginless css={[styles.orSection, orStyles]}>
          {t(l.appleSSO.Or)}
        </Typography>
      )}
    </div>
  );
};

export { AppleSSOButton };
