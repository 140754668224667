// @ts-strict-ignore
import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import type { AviaryColors } from "aviary-tokens";
import { useEffect, useRef } from "react";

import { Button } from "@aviary/components/Button";
import type { ButtonProps } from "@aviary/components/Button/Button.types";
import { useIsDS4Enabled } from "@aviary/hooks/useIsDS4Enabled";

import { useDropdownContext } from "../../DropdownContext";
import { DropdownChevron } from "../DropdownChevron";

import { DeprecatedDropdownButton } from "./DeprecatedDropdownButton";
import { useDropdownButtonProps } from "./useDropdownButtonProps";

import * as styles from "./DropdownButton.styles";

interface Props extends ButtonProps, Omit<ButtonProps, "isColor"> {
  /**
   * if true, button will have chevron icon, or custom icon
   *
   * @default true
   */
  hasIcon?: boolean;
  /**
   * optional custom icon that will be used in place of chevron
   */
  customIcon?: IconDefinition;
  /**
   * Centers the text content
   *
   * @default false
   */
  isCentered?: boolean;
  /**
   * Option to have primary colour dropdown button
   */
  isColor?: Extract<AviaryColors, "primary">;
}

const DropdownButton = ({ hasIcon = true, isCentered = false, ...props }: Props) => {
  const dropdownProps = useDropdownButtonProps();
  const { isDropdownOpen, prefixText, triggerElement, setTriggerElement } = useDropdownContext();

  const triggerRef = useRef(null);

  const initializeElement = () => {
    if (!triggerElement) {
      setTriggerElement(triggerRef.current);
    }
  };

  useEffect(() => {
    initializeElement();
  }, []);

  const isDS4Enabled = useIsDS4Enabled();

  const { children, customIcon, isText, isColor, ...rest } = props;

  if (!isDS4Enabled) {
    return (
      <DeprecatedDropdownButton {...{ hasIcon, isCentered, ...props, ...dropdownProps, ...rest }}>
        {children}
      </DeprecatedDropdownButton>
    );
  }

  const renderIcon = () => {
    if (hasIcon) {
      return <DropdownChevron customIcon={customIcon} />;
    }
  };

  const renderPrefix = () => {
    return (
      prefixText && (
        <span css={styles.prefix} data-testid="prefix-text">
          {prefixText}:
        </span>
      )
    );
  };

  const buttonStyles = [
    styles.dropdownButton,
    isCentered && styles.isCentered.button,
    !isColor && styles.color,
    isText && styles.isText,
  ];

  const contentWrapperStyles = [
    styles.dropdownButtonContentWrapper,
    isCentered && styles.isCentered.contentWrapper,
  ];

  return (
    <Button
      {...dropdownProps}
      innerRef={triggerRef}
      css={buttonStyles}
      isColor={isColor ? isColor : "system"}
      {...rest}
      aria-haspopup="true"
      aria-expanded={isDropdownOpen}
    >
      <div css={contentWrapperStyles}>
        {renderPrefix()}
        <span css={styles.text}>{children}</span>
      </div>
      {renderIcon()}
    </Button>
  );
};

export { DropdownButton };
