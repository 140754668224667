import { v4 as uuidv4 } from "uuid";

export enum EventType {
  FS_SIGNUP = "fs_signup",
  EEV2_SIGNUP = "ee_signup",
}

const generateEventId = (): string => {
  return uuidv4();
};

export const getEventId = (eventType: EventType): string => {
  if (typeof window === "undefined" || !window.sessionStorage) {
    return generateEventId();
  }

  const key = `eventId_${eventType}`;
  const storedEventId = sessionStorage.getItem(key);

  if (storedEventId) {
    return storedEventId;
  }

  const newEventId = generateEventId();
  sessionStorage.setItem(key, newEventId);

  return newEventId;
};

export const refreshEventId = (eventType: EventType): string => {
  if (typeof window === "undefined" || !window.sessionStorage) {
    return generateEventId();
  }

  const key = `eventId_${eventType}`;
  const newEventId = generateEventId();
  sessionStorage.setItem(key, newEventId);

  return newEventId;
};

export const initializeEventIds = (): void => {
  if (typeof window === "undefined" || !window.sessionStorage) {
    return;
  }

  Object.values(EventType).forEach(eventType => {
    getEventId(eventType as EventType);
  });
};

export const getAllEventIds = (): Record<string, string> => {
  if (typeof window === "undefined" || !window.sessionStorage) {
    return {};
  }

  const eventIds: Record<string, string> = {};

  Object.values(EventType).forEach(eventType => {
    eventIds[eventType] = getEventId(eventType as EventType);
  });

  return eventIds;
};
