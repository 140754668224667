import { EventType, getEventId, refreshEventId } from "./eventIdManager";

const logGaSignupEvent = (practitionerId: string, storeId: string, isEmerson?: boolean) => {
  // Standard FS signup tracking
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "practitioner-signup",
    practitionerId,
    storeId,
    eventId: getEventId(EventType.FS_SIGNUP),
  });

  refreshEventId(EventType.FS_SIGNUP);

  // Emerson-specific tracking
  if (isEmerson) {
    window.dataLayer.push({
      event: "signup",
      gaEventCategory: "emerson-ecologics-v2",
      gaEventAction: "main-form-signup",
      eventId: getEventId(EventType.EEV2_SIGNUP),
    });
    refreshEventId(EventType.EEV2_SIGNUP);
  }
};

export { logGaSignupEvent };
