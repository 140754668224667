import { useHasMultipleStorePlatformsQuery } from "@shared/data/queries/HasMultipleStorePlatformsQuery";

import { useAuthedExperiment } from "../useExperiment/useAuthedExperiment";
import { useFlippers } from "../useFlippers/useFlippers";
import { useSharedGlobalConfig } from "../useSharedGlobalConfig/useSharedGlobalConfig";
import { useSonicSegmentProvidersExperiment } from "../useSonicSegmentProviderExperiment/useSonicSegmentProviderExperiment";

const useSonicNPIImprovementsExperiment = () => {
  const [isSonicNPIImprovementsFlipper] = useFlippers("sonic_onboarding_npi_improvements");

  const isSonicSegmentProvidersEnabled = useSonicSegmentProvidersExperiment();
  const { isEmerson, isUnauthenticated } = useSharedGlobalConfig();
  const { data: officeData } = useHasMultipleStorePlatformsQuery({ skip: isUnauthenticated });
  const { hasMultipleStorePlatforms } = officeData?.currentOffice?.user ?? {};

  const isExperimentEnabled =
    isSonicSegmentProvidersEnabled &&
    isSonicNPIImprovementsFlipper &&
    !isEmerson &&
    !hasMultipleStorePlatforms;

  const group = useAuthedExperiment({
    experiment: "sonic_npi_improvements_experiment_2024",
    groups: ["CONTROL", "EXPERIMENT"],
    e2eGroup: "CONTROL",
    owner: "sonic",
  });

  return isExperimentEnabled && group === "EXPERIMENT";
};

export { useSonicNPIImprovementsExperiment };
