// @ts-strict-ignore
import type { HTMLProps, ReactNode, JSX } from "react";
import { Children, isValidElement, useMemo, useState, useEffect } from "react";

import { CarouselContext } from "./CarouselContext";
import { CarouselItems } from "./CarouselItems";
import type { DirectionType } from "./types";

import * as styles from "./Carousel.styles";

const CarouselItemsType = (<CarouselItems />).type;

/** @deprecated Please use NewCarousel instead */
interface CarouselProps
  extends Omit<HTMLProps<HTMLDivElement>, "onChange" | "autoPlay" | "action" | "title"> {
  /**
   * Determines the starting CarouselItem when loaded.
   *
   * @default 0
   */
  initialIndex?: number;

  /**
   * Allows you to set a new activeIndex by passing in a new index value
   *
   */
  newIndex?: number;

  /**
   * Determines the if the Carousel should loop or not
   *
   * @default false;
   */
  loop?: boolean;

  /**
   * Number of items to show at once
   *
   * @default 1
   */
  itemsToShow?: number;

  /**
   * Number of items to scroll
   *
   * @default 1
   */
  itemsToScroll?: number;
  /**
   * Function to trigger when the carousel moves forward of back
   */
  onChange?: (activeIndex: number) => void;
  /**
   * CarouselItems, CarouselRadio etc.
   *
   * @default undefined
   */
  children?: ReactNode;

  autoPlay?: {
    speedInMs: number;
  };
}

/** @deprecated Please use NewCarousel instead */
const Carousel = ({
  initialIndex = 0,
  newIndex,
  loop = false,
  children,
  itemsToShow = 1,
  itemsToScroll,
  onChange = () => null,
  autoPlay,
  ...rest
}: CarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const [direction, setDirection] = useState<DirectionType>("right");

  const calculateCarouselItemsCount = () => {
    let count = 0;
    Children.forEach(children, child => {
      if (isValidElement(child) && child.type === CarouselItemsType) {
        count = Children.count((child as JSX.Element).props.children);
      }
    });

    return count;
  };

  const carouselItemsCount = useMemo(() => calculateCarouselItemsCount(), [children]);

  const onSetActiveIndex = (targetIndex: number) => {
    onChange(targetIndex);
    setActiveIndex(targetIndex);
  };

  const getItemsToScroll = () => itemsToScroll || itemsToShow;

  useEffect(() => {
    if (!loop && activeIndex === carouselItemsCount - 1) return;
    if (!autoPlay) return;
    const timeout = setTimeout(() => {
      let nextIndex = activeIndex + getItemsToScroll();
      if (nextIndex >= carouselItemsCount) {
        nextIndex = loop ? nextIndex % carouselItemsCount : carouselItemsCount - 1;
      }
      onSetActiveIndex(nextIndex);
      setDirection("right");
    }, autoPlay.speedInMs);

    return () => clearTimeout(timeout);
  }, [activeIndex, loop, getItemsToScroll(), carouselItemsCount]);

  useEffect(() => {
    // setActiveIndex(0);
    setDirection("right");
  }, [children]);

  useEffect(() => {
    if (newIndex > activeIndex && newIndex <= carouselItemsCount) {
      setActiveIndex(newIndex);
      setDirection("right");
    }

    if (newIndex < activeIndex && newIndex >= 0) {
      setActiveIndex(newIndex);
      setDirection("left");
    }
  }, [newIndex]);

  return (
    <CarouselContext.Provider
      value={{
        activeIndex,
        setActiveIndex: onSetActiveIndex,
        carouselItemsCount,
        loop,
        direction,
        setDirection,
        itemsToShow,
        itemsToScroll: getItemsToScroll(),
      }}
    >
      <div css={styles.carousel} {...rest}>
        <div css={styles.carouselContainer}>{children}</div>
      </div>
    </CarouselContext.Provider>
  );
};

export { Carousel, type CarouselProps };
