// @ts-strict-ignore
import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";

import { animations } from "@styles";

import type { AviaryAvatarSize } from "./Avatar.types";

const sizeMaps = {
  xsmall: 2.5,
  small: 3.5,
  medium: 5,
  large: 7.5,
  xlarge: 10,
};

export const avatar = (theme: Theme) => css`
  ${animations.transition()}
  font-weight: ${typography.weightSemiBold};
  position: relative;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-shrink: 0;

  background-color: ${theme.system.backgroundMutedHover};
  color: ${theme.text.subdued};
`;

export const image = css`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export const avatarWithStatus = css`
  opacity: 0.7;
`;

export const overlayChip = css`
  position: absolute;
  font-size: initial;
`;

export const userStatusPosition = css`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
`;

// Platform badge is smaller and needs to have less offset
const overlayChipSize = (size: AviaryAvatarSize, isPlatformBadge: boolean) =>
  isPlatformBadge ? sizeMaps[size] - 1 : sizeMaps[size];

export const overlayPosition = {
  topRight: (size, isPlatformBadge) => css`
    --translate: calc(calc(${overlayChipSize(size, isPlatformBadge)}rem * 0.3) + 0.5rem);
    transform: translate(var(--translate), calc(var(--translate) * -1));
  `,
  topLeft: (size, isPlatformBadge) => css`
    --translate: calc(calc(calc(${overlayChipSize(size, isPlatformBadge)}rem * 0.3) + 0.5rem) * -1);
    transform: translate(var(--translate), var(--translate));
  `,
  bottomLeft: (size, isPlatformBadge) => css`
    --translate: calc(calc(${overlayChipSize(size, isPlatformBadge)}rem * 0.3) + 0.5rem);
    transform: translate(calc(var(--translate) * -1), var(--translate));
  `,
  bottomRight: (size, isPlatformBadge) => css`
    --translate: calc(calc(${overlayChipSize(size, isPlatformBadge)}rem * 0.3) + 0.5rem);
    transform: translate(var(--translate), var(--translate));
  `,
  center: () => css`
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  `,
};

export const sizes = {
  xsmall: (theme: Theme) => css`
    height: ${sizeMaps.xsmall}rem;
    width: ${sizeMaps.xsmall}rem;
    font-size: ${theme.aviaryTypography.body.fontSize};
  `,
  small: (theme: Theme) => css`
    height: ${sizeMaps.small}rem;
    width: ${sizeMaps.small}rem;
    font-size: ${theme.aviaryTypography.body.fontSize};
  `,
  medium: (theme: Theme) => css`
    height: ${sizeMaps.medium}rem;
    width: ${sizeMaps.medium}rem;
    font-size: ${theme.aviaryTypography.h3.fontSize};
  `,
  large: (theme: Theme) => css`
    height: ${sizeMaps.large}rem;
    width: ${sizeMaps.large}rem;
    font-size: ${theme.aviaryTypography.h2.fontSize};
  `,
  xlarge: (theme: Theme) => css`
    height: ${sizeMaps.xlarge}rem;
    width: ${sizeMaps.xlarge}rem;
    font-size: ${theme.aviaryTypography.h1.fontSize};
  `,
};
