import type * as Types from '@shared/types/graphqlGenerated.ts';

import * as Operations from './VerifySignInMFA.mutation';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifySignInMfaSharedMutationVariables = Types.Exact<{
  input: Types.AuthUserTwoFactorAuthenticationVerifyInput;
}>;


export type VerifySignInMfaSharedMutation = { auth?: Types.Maybe<{ userVerifyTwoFactorAuthentication?: Types.Maybe<(
      Pick<Types.AuthUserTwoFactorAuthenticationVerifyPayload, 'locked' | 'redirectPath' | 'returnToSignin' | 'newBackupCode' | 'roleType'>
      & { errors: Pick<Types.AuthUserTwoFactorAuthenticationVerifyError, 'message'> }
    )> }> };


export type VerifySignInMfaSharedMutationMutationFn = Apollo.MutationFunction<VerifySignInMfaSharedMutation, VerifySignInMfaSharedMutationVariables>;

/**
 * __useVerifySignInMfaSharedMutation__
 *
 * To run a mutation, you first call `useVerifySignInMfaSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifySignInMfaSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifySignInMfaSharedMutation, { data, loading, error }] = useVerifySignInMfaSharedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifySignInMfaSharedMutation(baseOptions?: Apollo.MutationHookOptions<VerifySignInMfaSharedMutation, VerifySignInMfaSharedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifySignInMfaSharedMutation, VerifySignInMfaSharedMutationVariables>(Operations.VerifySignInMFA_Shared_Mutation, options);
      }
export type VerifySignInMfaSharedMutationHookResult = ReturnType<typeof useVerifySignInMfaSharedMutation>;
export type VerifySignInMfaSharedMutationMutationOptions = Apollo.BaseMutationOptions<VerifySignInMfaSharedMutation, VerifySignInMfaSharedMutationVariables>;