import { ApolloLink } from "@apollo/client";

import { getAllEventIds } from "@shared/helpers/eventIdManager";

export const getAddEventIdLink = () => {
  return new ApolloLink((operation, forward) => {
    operation.extensions.eventIds = getAllEventIds();

    return forward(operation);
  });
};
