import { useHasMultipleStorePlatformsQuery } from "@shared/data/queries/HasMultipleStorePlatformsQuery";

import { useFlippers } from "../useFlippers/useFlippers";
import { useSharedGlobalConfig } from "../useSharedGlobalConfig/useSharedGlobalConfig";

const useSonicSegmentProvidersExperiment = () => {
  const [isSonicSegmentProvidersEnabled] = useFlippers("sonic_segment_providers");
  const { isEmerson, isUnauthenticated } = useSharedGlobalConfig();
  const { data: officeData } = useHasMultipleStorePlatformsQuery({ skip: isUnauthenticated });
  const { hasMultipleStorePlatforms } = officeData?.currentOffice?.user ?? {};

  return isSonicSegmentProvidersEnabled && !isEmerson && !hasMultipleStorePlatforms;
};

export { useSonicSegmentProvidersExperiment };
