import type * as Types from '@shared/types/graphqlGenerated.ts';

import * as Operations from './ResendSignInMFAPasscode.mutation';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendSignInMfaPasscodeSharedMutationVariables = Types.Exact<{
  input: Types.AuthUserTwoFactorAuthenticationResendInput;
}>;


export type ResendSignInMfaPasscodeSharedMutation = { auth?: Types.Maybe<{ userResendTwoFactorAuthentication?: Types.Maybe<(
      Pick<Types.AuthUserTwoFactorAuthenticationResendPayload, 'sent'>
      & { errors: Pick<Types.AuthUserTwoFactorAuthenticationResendError, 'message'> }
    )> }> };


export type ResendSignInMfaPasscodeSharedMutationMutationFn = Apollo.MutationFunction<ResendSignInMfaPasscodeSharedMutation, ResendSignInMfaPasscodeSharedMutationVariables>;

/**
 * __useResendSignInMfaPasscodeSharedMutation__
 *
 * To run a mutation, you first call `useResendSignInMfaPasscodeSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSignInMfaPasscodeSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSignInMfaPasscodeSharedMutation, { data, loading, error }] = useResendSignInMfaPasscodeSharedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendSignInMfaPasscodeSharedMutation(baseOptions?: Apollo.MutationHookOptions<ResendSignInMfaPasscodeSharedMutation, ResendSignInMfaPasscodeSharedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendSignInMfaPasscodeSharedMutation, ResendSignInMfaPasscodeSharedMutationVariables>(Operations.ResendSignInMFAPasscode_Shared_Mutation, options);
      }
export type ResendSignInMfaPasscodeSharedMutationHookResult = ReturnType<typeof useResendSignInMfaPasscodeSharedMutation>;
export type ResendSignInMfaPasscodeSharedMutationMutationOptions = Apollo.BaseMutationOptions<ResendSignInMfaPasscodeSharedMutation, ResendSignInMfaPasscodeSharedMutationVariables>;