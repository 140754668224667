import { Trans, useTranslation } from "react-i18next";

import { Button, Link, Typography } from "@aviary";
import { BackupCode } from "@shared/MultifactorAuthentication/components/legacy/BackupCode/BackupCode";
import { useUserRolesTypeSharedQuery as useUserRolesTypeQuery } from "@shared/data/queries/UserRolesTypeQuery/UserRolesType.query.generated";
import { getPlatformName } from "@shared/helpers/getPlatformName";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { l } from "@shared/locales/i18n";

import * as styles from "./NewBackupCodeView.styles";

const twoFactorSettingsRoutes = {
  patient: "/u/account",
} as const;

interface Props {
  newBackupCode: string;
  redirectPath: string;
}

const NewBackupCodeView = ({ newBackupCode, redirectPath }: Props) => {
  const { t } = useTranslation();
  const { storePlatform } = useSharedGlobalConfig();

  const navigateToRedirectPath = () => {
    window.location.assign(redirectPath);
  };

  const { data: rolesQueryData, loading: rolesQueryLoading } = useUserRolesTypeQuery();

  const hasPractitionerOrClerkRole = rolesQueryData?.viewer?.user?.roles?.some(
    role => role.type === "Practitioner" || role.type === "Clerk"
  );

  const renderCopy = () => {
    if (rolesQueryLoading) {
      return <Typography isSkeleton />;
    }

    if (hasPractitionerOrClerkRole) {
      return (
        <Typography isMarginless>
          Here is your new backup code. Please store it securely.
        </Typography>
      );
    }

    return (
      <Trans
        i18nKey={l.mfa.newBackupCodeView.subtitle}
        values={{ storePlatform: t(getPlatformName(storePlatform)) }}
      >
        <Typography isMarginless>
          Here is your new backup code. Please store it securely.
        </Typography>
        <Typography>
          You can view this again in your {storePlatform}
          {renderAccountSettingsLink()}.
        </Typography>
      </Trans>
    );
  };

  const renderAccountSettingsLink = () => {
    const accountSettingsRoute = twoFactorSettingsRoutes.patient;

    if (accountSettingsRoute) {
      return <Link href={accountSettingsRoute}>Account settings</Link>;
    }

    return <strong>Account settings</strong>;
  };

  return (
    <div css={styles.wrapper}>
      <Typography type="h1" sizeOverride="h3">
        {t(l.mfa.backupCode)}
      </Typography>
      {renderCopy()}
      <BackupCode backupCode={newBackupCode} />
      <Button css={styles.nextButton} onClick={navigateToRedirectPath}>
        {t(l.sharedCommon.Next)}
      </Button>
    </div>
  );
};

export { NewBackupCodeView, twoFactorSettingsRoutes };
