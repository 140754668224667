import { gql } from "@apollo/client";

const VerifySignInMFA_Shared_Mutation = gql`
  mutation VerifySignInMFA_Shared_Mutation($input: AuthUserTwoFactorAuthenticationVerifyInput!) {
    auth {
      userVerifyTwoFactorAuthentication(input: $input) {
        locked
        redirectPath
        returnToSignin
        newBackupCode
        roleType
        errors {
          message
        }
      }
    }
  }
`;

export { VerifySignInMFA_Shared_Mutation };
