// @ts-strict-ignore
import { faExclamationCircle as faRegExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@shared/react-fontawesome";
import { Link } from "@shared/react-router-dom";

import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";

import * as styles from "./Stepper.styles";

interface Props {
  /**
   * Specifies the current step you are on
   *
   * @required
   */
  currentStep: number;
  /**
   * An array of objects containing `label` (required) and `tooltipContent` (optional) keys.
   * When the entire array is not provided, you must provide a number of steps instead.
   * Note: the default tooltip content for each step is the `label` - `tooltipContent` will only
   * override this when the current step is active.
   */
  steps: { label: string; tooltipContent?: string; href?: string; to?: string }[] | number;
  /**
   * Specifies whether or not only the current step (status) label is displayed beneath the stepper,
   * or if every step has a label beneath it. If set to true, the past and future steps are displayed as tooltips.
   *
   * @default false
   */
  showStatusLabel?: boolean;
  /**
   * Specifies which direction the status label should be placed
   *
   * @default null
   */
  statusLabelPosition?: "left" | "center";
  /**
   * Specify if there is an error in the progress of the stepper
   *
   * @default false
   */
  isError?: boolean;
  /**
   * Specify if the connector between the steps is a dashed line instead of solid
   *
   * @default false
   */
  isDashed?: boolean;
  /**
   * Specify the size of the atoms and connectors
   *
   * @default "large"
   */
  size?: "small" | "large";
  /**
   * Specifies that the final step is incomplete and should not have a filled step atom
   *
   * @default false
   */
  isIncompleteFinalStep?: boolean;
  /**
   * Hides steps atoms
   *
   * @default false
   */
  hideAtoms?: boolean;
  /**
   * Makes ends of stepper lines rounded
   *
   * @default false
   */
  isRounded?: boolean;
  /**
   * Changes color of completed indicators to dark gray
   *
   * @default false
   */
  isGray?: boolean;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/display/Stepper
 */
const Stepper = ({
  currentStep,
  steps,
  showStatusLabel,
  statusLabelPosition,
  isError,
  isDashed,
  size = "large",
  isIncompleteFinalStep,
  hideAtoms,
  isRounded,
  isGray,
  ...rest
}: Props) => {
  const isCurrent = step => {
    return step === currentStep;
  };

  const stepsHaveLabels = Array.isArray(steps);
  const stepCount = stepsHaveLabels ? steps.length : steps;

  const isFirstStep = step => {
    return step === 1;
  };

  const isPreFinalStep = step => {
    return step === stepCount - 1;
  };

  const isFinalStep = step => {
    return isCurrent(step) && step === stepCount;
  };

  const isPreviousStep = step => {
    return step < currentStep;
  };

  const isDashedAndPreviousStep = step => isDashed && isPreviousStep(step);
  const isCurrentErrorStep = step => isError && isCurrent(step);
  const isSuccessfulFinalStep = step => !isError && !isIncompleteFinalStep && isFinalStep(step);

  const prepareCompletedConnectorStyle = () =>
    isGray ? styles.connectors.completedGray : styles.connectors.completed;

  const stepStyle = step => {
    const dashedStyles = [
      styles.steps.isDashedBase[size],
      (isSuccessfulFinalStep(step) || isPreviousStep(step) || isCurrentErrorStep(step)) &&
        styles.steps.completed,
      isCurrentErrorStep(step) && styles.steps.dashedError,
    ];

    const baseStyles = [
      styles.steps.base,
      styles.steps.size[size],
      isCurrent(step) && styles.steps.current,
      isCurrent(step) && styles.steps.currentBorder.successful[size],
      isPreviousStep(step) && styles.steps.previous,
      isError && isPreviousStep(step) && styles.steps.error,
      isCurrentErrorStep(step) && styles.steps.currentBorder.error[size],
      isSuccessfulFinalStep(step) && styles.steps.completed,
    ];

    return baseStyles.concat(isDashed ? dashedStyles : []);
  };

  const linkStepStyles = [isDashed ? styles.steps.dashedLink : styles.steps.link];

  const connectorStyle = step => {
    return [
      styles.connectors.base,
      styles.connectors.size[size],
      showStatusLabel ? styles.connectors.labels.status : styles.connectors.labels.all,
      isCurrent(step) && styles.connectors.current,
      isPreviousStep(step) && prepareCompletedConnectorStyle(),
      isError && isPreviousStep(step) && styles.connectors.error,
      isDashed && styles.connectors.dashed.base,
      isDashed && styles.connectors.dashed[size],
      isRounded && isFirstStep(step) && styles.connectors.firstStep,
      isRounded && isPreFinalStep(step) && styles.connectors.finalStep,
    ];
  };

  const stepWrapperStyle = stepIndex => {
    return [
      styles.stepWrapper.base,
      showStatusLabel ? styles.stepWrapper.labels.status : styles.stepWrapper.labels.all,
      ...connectorStyle(stepIndex),
    ];
  };

  const statusLabelStyle = step => {
    return [
      statusLabelPosition === "center" && styles.labels.status.centered,
      styles.labels.status.size[size],
      isCurrentErrorStep(step) && styles.labels.error,
    ];
  };

  const renderStepIcon = step => {
    if (isDashedAndPreviousStep(step) || isSuccessfulFinalStep(step)) {
      return <FontAwesomeIcon icon={faCheckCircle} />;
    }

    if (isDashed && isCurrentErrorStep(step)) {
      return <FontAwesomeIcon icon={faExclamationCircle} />;
    }
  };

  const renderErrorLabelIcon = i => {
    if (isCurrentErrorStep(i + 1)) return <FontAwesomeIcon icon={faRegExclamationCircle} />;
  };

  const stepLabels = stepsHaveLabels && steps?.map(step => step.label);

  const renderStep = i => {
    const stepIndex = i + 1;

    const renderTooltip = child => {
      if (!stepsHaveLabels) return child;

      const renderTooltipContent = () => {
        if (isCurrent(stepIndex) && steps[i]?.tooltipContent) return steps[i]?.tooltipContent;

        return stepLabels[i];
      };

      const isTooltipHidden =
        (!showStatusLabel && !isCurrent(stepIndex)) ||
        (!showStatusLabel && isCurrent(stepIndex) && !steps[i]?.tooltipContent);

      return (
        <Tooltip
          tooltipContent={renderTooltipContent()}
          placement="top"
          isTooltipHidden={isTooltipHidden}
          data-testid={`tooltip-step-${stepIndex}`}
        >
          {child}
        </Tooltip>
      );
    };

    const shouldRenderHrefLinkAtom = isPreviousStep(stepIndex) && steps[i]?.href && !isError;
    const shouldRenderToLinkAtom = isPreviousStep(stepIndex) && steps[i]?.to && !isError;
    const renderAtomType = () => {
      if (hideAtoms) return null;

      if (shouldRenderHrefLinkAtom) {
        return (
          <a
            href={steps[i].href}
            css={[stepStyle(stepIndex), linkStepStyles]}
            aria-label={steps[i].label}
            data-testid="step-atom"
          >
            {renderStepIcon(stepIndex)}
          </a>
        );
      }

      if (shouldRenderToLinkAtom) {
        return (
          <Link
            to={steps[i].to}
            css={[stepStyle(stepIndex), linkStepStyles]}
            aria-label={steps[i].label}
            data-testid="step-atom"
          >
            {renderStepIcon(stepIndex)}
          </Link>
        );
      }

      return (
        <div css={stepStyle(stepIndex)} data-testid="step-atom">
          {renderStepIcon(stepIndex)}
        </div>
      );
    };

    return renderTooltip(renderAtomType());
  };

  const renderEachLabel = i => {
    if (showStatusLabel || !stepsHaveLabels) return null;

    return (
      <Typography
        key={`step-label-${i}`}
        css={[styles.labels.all, isCurrentErrorStep(i + 1) && styles.labels.error]}
      >
        {renderErrorLabelIcon(i)}
        {stepLabels[i]}
      </Typography>
    );
  };

  const renderSteps = () => {
    return Array.from({ length: stepCount }).map((_, i) => (
      <div key={`step-${i}`} css={stepWrapperStyle(i + 1)}>
        {renderStep(i)}
        {renderEachLabel(i)}
      </div>
    ));
  };

  const renderCurrentStatusLabel = () => {
    if (!showStatusLabel) return null;

    return (
      <Typography css={statusLabelStyle(currentStep)} sizeOverride={size === "large" ? "h4" : null}>
        {renderErrorLabelIcon(currentStep - 1)}
        {stepLabels[currentStep - 1]}
      </Typography>
    );
  };

  return (
    <div {...rest}>
      <div css={styles.stepperWrapper}>{renderSteps()}</div>
      {renderCurrentStatusLabel()}
    </div>
  );
};

export { Stepper };
