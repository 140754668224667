// @ts-strict-ignore
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import type { FloatingLabelInputProps } from "@aviary";
import { FloatingLabelInput, Tooltip } from "@aviary";
import { IsNameValid } from "@unauthenticated/shared/components/NameValidation/NameValidation";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./LastNameInput.styles";
interface Props extends Omit<FloatingLabelInputProps, "id" | "label" | "type"> {
  errors?: ReactNode[];
  value: string;
  isDirty?: boolean;
  isLoading: boolean;
}

const LastNameInput = ({ wrapperStyles, errors, isDirty, isLoading, disabled, ...rest }: Props) => {
  const { t } = useTranslation();
  const isValid = () => {
    if (!isDirty) return null;
    const newErrors = errors || [];
    if (rest?.value) {
      if (!IsNameValid(rest.value)) {
        return [...newErrors, t(l.common.LastNameInvalid)];
      }

      return newErrors;
    }

    return [...newErrors, t(l.common.LastNameRequired)];
  };

  const getErrors = () => {
    return isValid();
  };

  const renderInput = () => (
    <FloatingLabelInput
      id="lastName"
      data-e2e="last_name"
      type="text"
      name="lastName"
      label={t(l.common.LastName)}
      wrapperStyles={wrapperStyles}
      errors={getErrors()}
      disabled={disabled || isLoading}
      {...rest}
    />
  );

  if (disabled) {
    return (
      <Tooltip
        tooltipContent={t(l.common.EditOnceSignedIn)}
        css={[styles.Tooltip, wrapperStyles]}
        data-testid="disabled-tooltip"
      >
        {renderInput()}
      </Tooltip>
    );
  }

  return renderInput();
};
export { LastNameInput };
