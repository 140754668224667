import { Trans } from "react-i18next";

import { Link, Typography } from "@aviary";
import { sharedRoutes } from "@shared/data/sharedRoutes";
import { l } from "@unauthenticated/shared/locales/i18n";

const TERMS_OF_SERVICE_URL = "https://fullscript.com/terms-of-service";

import * as styles from "./TermsOfService.styles";

const TermsOfService = ({ ...rest }) => {
  const linkStyles = [styles.link, styles.appColor];

  return (
    <Typography type="footnote" {...rest}>
      <Trans i18nKey={l.common.TermsOfServiceAndPrivacyStatement}>
        By creating your account you agree to our{" "}
        <Link isColor="success" css={linkStyles} href={TERMS_OF_SERVICE_URL} noExternalIcon>
          Terms of Service
        </Link>
        and
        <Link
          isColor="success"
          css={linkStyles}
          href={sharedRoutes.external.privacyPolicy}
          noExternalIcon
        >
          Privacy Statement
        </Link>
      </Trans>
    </Typography>
  );
};

export { TermsOfService };
