import { gql } from "@apollo/client";

const UserRolesType_Shared_Query = gql`
  query UserRolesType_Shared_Query {
    viewer {
      user {
        id
        roles {
          id
          type
        }
      }
    }
  }
`;

export { UserRolesType_Shared_Query };
