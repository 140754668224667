// @ts-strict-ignore
import { css } from "@emotion/react";

export const carouselItems = ({ carouselItemsCount, itemsToShow }) => css`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: ${carouselItemsCount < itemsToShow ? "flex-start" : "space-between"};
  align-items: stretch;
  padding: 2px;
`;

// in compbination with parent's justify-content: "space-between" creates a 1rem gap between items
export const animatedDiv = ({ itemsToShow, carouselItemsCount }) => css`
  width: calc((100% - ${itemsToShow - 1}rem) / ${itemsToShow});
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:nth-of-type(2) {
    margin: 0 ${carouselItemsCount < itemsToShow ? "1rem" : "0"};
  }
`;
