import type { ReactNode, JSX } from "react";
import { cloneElement, Children, isValidElement } from "react";

import { Carousel, CarouselItems, CarouselRadio } from "@aviary";

import * as styles from "./SignUpCarousel.styles";

interface Props {
  children?: ReactNode;
}

const SignUpCarousel = ({ children }: Props) => {
  const renderChildren = () => {
    return Children.map(children, child => {
      if (isValidElement(child)) {
        return cloneElement(child, { css: styles.item, ...(child as JSX.Element).props });
      }
    });
  };

  return (
    <Carousel loop autoPlay={{ speedInMs: 4000 }} css={styles.container}>
      <CarouselItems>{renderChildren()}</CarouselItems>
      <CarouselRadio />
    </Carousel>
  );
};

export { SignUpCarousel };
