import { Trans, useTranslation } from "react-i18next";

import { Link, Spacer, Typography, useToast } from "@aviary";
import {
  useResendSignInMfaPasscodeSharedMutation as useResendMFAPasscode,
  type ResendSignInMfaPasscodeSharedMutation as ResendMFAPasscodeData,
} from "@shared/MultifactorAuthentication/data/ResendSignInMFAPasscode.mutation.generated";
import { l } from "@shared/locales/i18n";
import { TwoFactorAuthenticationMethods } from "@shared/types/graphqlGenerated";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";

import * as styles from "./ResendOtp.styles";

interface Props {
  token: string;
  otpMethod: TwoFactorAuthenticationMethods;
  email?: string;
  phoneLast4?: string;
}

const ResendOtp = ({ token, otpMethod, email, phoneLast4 }: Props) => {
  const { t } = useTranslation();
  const { makeToast } = useToast();

  const onResendPasscodeComplete = (data: ResendMFAPasscodeData) => {
    const hasErrors = data?.auth?.userResendTwoFactorAuthentication?.errors?.message;
    const isSent = data?.auth?.userResendTwoFactorAuthentication?.sent;
    const isEmailOtpMethod = otpMethod === TwoFactorAuthenticationMethods.Email;

    if (hasErrors || !isSent) {
      const errorMessage = isEmailOtpMethod ? t(l.mfa.emailNotSent) : t(l.mfa.textMessageNotSent);

      return makeToast("error", errorMessage);
    }

    if (isSent) {
      const successMessage = isEmailOtpMethod
        ? t(l.mfa.emailHasBeenSent, { email })
        : t(l.mfa.textMessageHasBeenSent, { phoneLast4 });

      makeToast("success", successMessage);
    }
  };

  const [resendPasscode, { loading: loadingResendPasscode }] = useResendMFAPasscode({
    onCompleted: onResendPasscodeComplete,
    onError: ({ message }) => makeToast("error", message),
  });

  const onResendPasscode = () => {
    if (!loadingResendPasscode) {
      gRecaptchaExecute(captchaToken => {
        resendPasscode({
          variables: {
            input: {
              token,
              captchaToken,
            },
          },
        });
      });
    }
  };

  const renderResendText = () => {
    if (otpMethod === TwoFactorAuthenticationMethods.Sms) {
      return (
        <Typography type="footnote" isMarginless>
          <Trans i18nKey={l.mfa.didntGetTextMessage}>
            Didn't get the text message?
            <Link onClick={onResendPasscode}>Resend</Link>.
          </Trans>
        </Typography>
      );
    }

    return (
      <Typography type="footnote" isMarginless>
        <Trans i18nKey={l.mfa.didntGetEmail}>
          Didn't get the email? Check your spam folder or
          <Link onClick={onResendPasscode}>resend</Link>.
        </Trans>
      </Typography>
    );
  };

  return (
    <div css={styles.wrapper}>
      {renderResendText()}
      <Spacer height="half" />
      <Typography type="footnote" css={styles.expirationMessage} isMarginless>
        {t(l.mfa.VerifyMFAForm.codeWillExpire)}
      </Typography>
    </div>
  );
};

export { ResendOtp };
