import type { SerializedStyles } from "@emotion/serialize";
import { useTranslation } from "react-i18next";

import { Typography } from "@aviary";
import { l } from "@shared/locales/i18n";

import * as styles from "./OrSection.styles";
interface OrSectionProps {
  orStyles?: SerializedStyles;
}

const OrSection = ({ orStyles }: OrSectionProps) => {
  const { t } = useTranslation();

  return (
    <Typography type="footnote" isMarginless css={[styles.orSection, orStyles]}>
      {t(l.magicLink.Or)}
    </Typography>
  );
};

export { OrSection };
